/* VA Style Variables */
@import '../colors';

$primary: #00669e;
$secondary: #efb800;
$light: #e6e6e6;

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg: #495058;
$body-color: #222;

$footer-bg: $primary;

$tooltip-icon-color: $primary;

/* Proccess Page Styling */
$process-page-themes: (
  'claim': $primary,
  'holder': $primary
);

$dropdown-bg: #00669e;
$dropdown-hover-bg: #004064;
$dropdown-divider-bg: #777;

$dropdown-link-color: #ffffff;
$dropdown-link-hover-color: #ffffff;
$dropdown-link-hover-bg: #004064;

$mobile-navbar-bg: #495058;
$static-page-header-color: $primary;
$static-page-subtitle-color: #333;

/* Image Size & Image Source Variables */
$banner-image-url: url('/images/main_banner.png');

$claim-header-logo-url: url('/images/main_logo.png');
$claim-header-logo-height: 48px;
$claim-header-logo-width: 300px;

$holder-header-logo-url: $claim-header-logo-url;
$holder-header-logo-height: $claim-header-logo-height;
$holder-header-logo-width: $claim-header-logo-width;

$home-logo-url: url('/images/main_logo.png');
$home-logo-width: 520px;
$home-logo-height: 86px;
$home-logo-resize-value-map: (
  width: 112px,
  height: $home-logo-height,
  background-image: url('/images/state_seal.png')
);

$treasurer-logo-url: $home-logo-url;
$treasurer-logo-width: $home-logo-width;
$treasurer-logo-height: $home-logo-height;
$treasurer-logo-resize-value-map: $home-logo-resize-value-map;

$footer-logo-url: url('/images/state_seal.png');
$footer-logo-width: 193px;
$footer-logo-height: 150px;

$claimform-image-url: url('/images/sample_claim_form.png');

$icon-email-url: none;
$icon-facebook-url: url('/images/icon_facebook.png');
$icon-instagram-url: none;
$icon-twitter-url: url('/images/twitter.png');
$icon-youtube-url: url('/images/youtube.png');
$icon-linkedin-url: url('/images/linkedin.png');

$social-icon-width: 40px;
$social-icon-height: 40px;

$btn-pop-bg: $secondary;
$link-color: #0000ff;

$ticker-bg: #8a8a99;

// Update button color
.btn-pop {
  color: $black !important;
}
.btn-action {
  color: $black !important;
}

#skiptocontent a {
  z-index: 0 !important;
}

